'use client'
import { styled } from '../config'
import { SVGProps } from 'react'

export function CircleWrongSvg(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <circle cx="9" cy="9" r="9" />
      <path
        d="M12 6L6 12"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6L12 12"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const CircleWrong = styled(CircleWrongSvg, {
  fill: '#DB4E56',
})
