'use client'
import { styled } from '../config'

function Svg(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M8.00016 8.66666C8.36835 8.66666 8.66683 8.36818 8.66683 7.99999C8.66683 7.63181 8.36835 7.33333 8.00016 7.33333C7.63197 7.33333 7.3335 7.63181 7.3335 7.99999C7.3335 8.36818 7.63197 8.66666 8.00016 8.66666Z"
        stroke="black"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00016 4.00001C8.36835 4.00001 8.66683 3.70153 8.66683 3.33334C8.66683 2.96515 8.36835 2.66667 8.00016 2.66667C7.63197 2.66667 7.3335 2.96515 7.3335 3.33334C7.3335 3.70153 7.63197 4.00001 8.00016 4.00001Z"
        stroke="black"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00016 13.3333C8.36835 13.3333 8.66683 13.0349 8.66683 12.6667C8.66683 12.2985 8.36835 12 8.00016 12C7.63197 12 7.3335 12.2985 7.3335 12.6667C7.3335 13.0349 7.63197 13.3333 8.00016 13.3333Z"
        stroke="black"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const VerticalOptionsDots = styled(Svg)
