'use client'
import { styled } from '../config'

function Svg(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M16.6673 5L7.50065 14.1667L3.33398 10"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const CheckAnswer = styled(Svg, { stroke: '#59A26F' })
