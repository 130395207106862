import { styled } from '../config'

function ChevronDownSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      {...props}>
      <path
        d="M4.471 5.79561L0.237805 1.84571C-0.0547839 1.5727 -0.0547839 1.13124 0.237805 0.861136L0.941262 0.204755C1.23385 -0.0682523 1.70697 -0.0682523 1.99645 0.204755L4.99704 3.00454L7.99762 0.204756C8.29021 -0.068252 8.76333 -0.068252 9.05281 0.204756L9.76249 0.858232C10.0551 1.13124 10.0551 1.5727 9.76249 1.8428L5.5293 5.7927C5.23671 6.06861 4.76359 6.06861 4.471 5.79561Z"
        fill="black"
      />
    </svg>
  )
}

export const ChevronDownBold = styled(ChevronDownSvg, {})
