'use client'
import { styled } from '../config'

function Svg(props) {
  return (
    <svg
      enableBackground="new 0 0 134 170"
      version="1.1"
      viewBox="0 0 134 170"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="m9 123.22c1.66-2.12 3.13-4.41 5-6.31 8.71-8.83 17.54-17.54 26.3-26.32 2.3-2.31 4.95-3.46 8.18-2.67 6.31 1.55 8.3 8.96 3.67 13.72-5.05 5.11-10.1 10.23-15.32 15.16 0.06 0.13 0.13 0.26 0.19 0.39h56.95c7.21 0 11.73-4.5 11.73-11.69 0.01-30.02 0-60.05 0.01-90.07-0.39-7.03 9-10.97 13.71-5.79 0.99 1.06 1.61 2.46 2.4 3.7v96.48c-2.34 13.44-12.98 23.5-26.92 23.45-19.3 0.01-38.61 0-57.91 0-0.06 0.16-0.12 0.31-0.18 0.47 5.35 4.72 10.2 10.03 15.3 15.03 3.29 3.34 3.56 8.08 0.49 11.26-1.15 1.19-2.86 1.83-4.31 2.72h-3.69c-2.08-1.61-4.34-3.04-6.2-4.86-8.2-8.04-16.34-16.15-24.4-24.34-1.87-1.9-3.34-4.19-4.99-6.3-0.01-1.35-0.01-2.69-0.01-4.03z" />
    </svg>
  )
}

export const Enter = styled(Svg)
