'use client'
import { styled } from '../config'

function Svg(props) {
  return (
    <svg
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M16.5129 7.2207H13.5129V4.9707C13.5129 3.77723 13.0388 2.63264 12.1949 1.78872C11.3509 0.944809 10.2064 0.470703 9.01288 0.470703C7.8194 0.470703 6.67481 0.944809 5.8309 1.78872C4.98698 2.63264 4.51288 3.77723 4.51288 4.9707V7.2207H1.51288C1.11505 7.2207 0.733523 7.37874 0.452218 7.66004C0.170914 7.94135 0.0128784 8.32288 0.0128784 8.7207V19.2207C0.0128784 19.6185 0.170914 20.0001 0.452218 20.2814C0.733523 20.5627 1.11505 20.7207 1.51288 20.7207H16.5129C16.9107 20.7207 17.2922 20.5627 17.5735 20.2814C17.8548 20.0001 18.0129 19.6185 18.0129 19.2207V8.7207C18.0129 8.32288 17.8548 7.94135 17.5735 7.66004C17.2922 7.37874 16.9107 7.2207 16.5129 7.2207ZM6.01288 4.9707C6.01288 4.17505 6.32895 3.41199 6.89156 2.84938C7.45417 2.28677 8.21723 1.9707 9.01288 1.9707C9.80853 1.9707 10.5716 2.28677 11.1342 2.84938C11.6968 3.41199 12.0129 4.17505 12.0129 4.9707V7.2207H6.01288V4.9707ZM16.5129 19.2207H1.51288V8.7207H16.5129V19.2207Z"
        fill="#5368E6"
      />
    </svg>
  )
}

export const Bag = styled(Svg)
