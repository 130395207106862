'use client'
import { styled } from '../config'

function Svg(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M13.8596 6.94547C13.4241 5.78915 12.7891 4.71822 11.9834 3.78141C10.5891 2.16738 8.75566 1.125 7.3125 1.125C7.22669 1.12506 7.14203 1.14476 7.065 1.18258C6.98797 1.2204 6.92062 1.27535 6.8681 1.34321C6.81557 1.41107 6.77927 1.49004 6.76197 1.57409C6.74467 1.65814 6.74683 1.74504 6.76828 1.82812C7.2559 3.69141 6.24516 5.24074 5.17535 6.88078C4.28906 8.23922 3.375 9.6423 3.375 11.25C3.375 14.3515 5.89852 16.875 9 16.875C12.1015 16.875 14.625 14.3515 14.625 11.25C14.625 9.72773 14.3677 8.27965 13.8596 6.94547ZM10.1366 14.7196C9.77344 15.1063 9.31816 15.1875 9 15.1875C8.68184 15.1875 8.22656 15.1063 7.8634 14.7196C7.50023 14.3329 7.3125 13.7194 7.3125 12.9375C7.3125 12.0537 7.62258 11.3808 7.92211 10.7304C8.09613 10.3528 8.27367 9.96855 8.37914 9.55723C8.39034 9.51248 8.41237 9.47118 8.44329 9.43696C8.47421 9.40274 8.51307 9.37665 8.55645 9.36098C8.59984 9.34532 8.6464 9.34056 8.69206 9.34713C8.73771 9.35371 8.78104 9.37141 8.81824 9.39867C9.13979 9.64745 9.42569 9.93915 9.66797 10.2656C10.3068 11.1094 10.6875 12.1085 10.6875 12.9375C10.6875 13.7194 10.497 14.3357 10.1366 14.7196Z"
        fill="#BEC1C4"
      />
    </svg>
  )
}

export const DifficultyFlame = styled(Svg)
