'use client'
import clsx from 'clsx'
import { styled } from '../config'
import { forwardRef, SVGProps } from 'react'

export const CircleOkSvg = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(
  function CircleOKSvg(props, forwardRef: any) {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        className={clsx('fill-[#10A489]', props.className)}
        ref={forwardRef}>
        <circle cx="12" cy="12" r="12" />
        <path
          d="M17.3327 8L9.99935 15.3333L6.66602 12"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  }
)

export const CircleOk = styled(CircleOkSvg, { fill: '#10A489' })
