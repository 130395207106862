'use client'

export function EmptyImageRounded(props) {
  return (
    <svg
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        id="Vector"
        d="M12.9603 0.0471191H2.76146C2.22066 0.0477097 1.70217 0.262804 1.31977 0.645208C0.937364 1.02761 0.72227 1.5461 0.72168 2.0869V10.246C0.72227 10.7868 0.937364 11.3053 1.31977 11.6877C1.70217 12.0701 2.22066 12.2852 2.76146 12.2858H12.9603C13.5011 12.2852 14.0196 12.0701 14.402 11.6877C14.7844 11.3053 14.9995 10.7868 15.0001 10.246V2.0869C14.9995 1.5461 14.7844 1.02761 14.402 0.645208C14.0196 0.262804 13.5011 0.0477097 12.9603 0.0471191ZM10.4106 2.0869C10.7132 2.0869 11.009 2.17662 11.2606 2.34472C11.5121 2.51282 11.7082 2.75175 11.824 3.03129C11.9398 3.31083 11.9701 3.61843 11.9111 3.91519C11.852 4.21194 11.7063 4.48453 11.4924 4.69849C11.2784 4.91244 11.0058 5.05814 10.7091 5.11717C10.4123 5.1762 10.1047 5.1459 9.82518 5.03011C9.54564 4.91432 9.30671 4.71824 9.13861 4.46666C8.97051 4.21508 8.88079 3.9193 8.88079 3.61673C8.88121 3.21112 9.04253 2.82225 9.32933 2.53544C9.61614 2.24863 10.005 2.08732 10.4106 2.0869ZM2.76146 11.2659C2.49097 11.2659 2.23155 11.1584 2.04029 10.9672C1.84902 10.7759 1.74157 10.5165 1.74157 10.246V8.09054L4.76426 5.40377C5.0559 5.14511 5.43518 5.00738 5.82483 5.01864C6.21448 5.02989 6.58518 5.18927 6.86141 5.46432L8.93147 7.52992L5.19549 11.2659H2.76146ZM13.9802 10.246C13.9802 10.5165 13.8728 10.7759 13.6815 10.9672C13.4902 11.1584 13.2308 11.2659 12.9603 11.2659H6.63799L10.5078 7.39605C10.7818 7.16305 11.1295 7.03469 11.4892 7.03375C11.8488 7.03282 12.1972 7.15938 12.4724 7.39096L13.9802 8.64733V10.246Z"
        fill="black"
        fillOpacity="0.3"
      />
    </svg>
  )
}
