'use client'
import { styled } from '../config'

function Svg(props) {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M0.223652 7.21396L4.9484 1.01272C5.34123 0.497134 6.11324 0.485767 6.52108 0.989567L11.5411 7.1908C12.0704 7.84462 11.6051 8.82 10.7639 8.82H8.35C8.21193 8.82 8.1 8.93193 8.1 9.07V13C8.1 13.5523 7.65228 14 7.1 14H4.34C3.78772 14 3.34 13.5523 3.34 13V9.07C3.34 8.93193 3.22807 8.82 3.09 8.82H1.01908C0.191544 8.82 -0.277872 7.87221 0.223652 7.21396Z"
        fill={props.fill}
      />
    </svg>
  )
}

export const FilledArrow = styled(Svg, { fill: '#10A489' })
