'use client'
import { styled } from '../config'
import React from 'react'

const Svg = React.forwardRef((props, forwardRef: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardRef}>
      <path d="M12 2.25C6.615 2.25 2.25 6.615 2.25 12C2.25 17.385 6.615 21.75 12 21.75C17.385 21.75 21.75 17.385 21.75 12C21.75 6.615 17.385 2.25 12 2.25ZM8.14078 7.60969L13.0627 10.9378C13.3876 11.1718 13.6073 11.5243 13.6743 11.919C13.7413 12.3138 13.6502 12.719 13.4207 13.0471C13.1913 13.3752 12.8418 13.5998 12.4481 13.6723C12.0543 13.7447 11.6478 13.6593 11.3166 13.4344C11.171 13.3317 11.0442 13.2049 10.9416 13.0594L7.61344 8.1375C7.56221 8.06411 7.53844 7.97503 7.54631 7.88588C7.55417 7.79672 7.59316 7.71318 7.65645 7.64989C7.71974 7.5866 7.80328 7.54761 7.89244 7.53974C7.98159 7.53188 8.07067 7.55564 8.14406 7.60687L8.14078 7.60969ZM12 20.25C7.45312 20.25 3.75 16.5492 3.75 12C3.74614 10.8904 3.968 9.79163 4.4021 8.77048C4.83621 7.74933 5.47348 6.82714 6.27516 6.06C6.3375 5.99784 6.4116 5.94872 6.49312 5.9155C6.57465 5.88227 6.66197 5.86561 6.75 5.86648C6.83804 5.86735 6.92501 5.88574 7.00587 5.92057C7.08672 5.9554 7.15983 6.00598 7.22093 6.06935C7.28204 6.13273 7.32992 6.20764 7.36177 6.28971C7.39363 6.37178 7.40883 6.45937 7.40649 6.54737C7.40415 6.63538 7.38431 6.72203 7.34814 6.80229C7.31196 6.88255 7.26017 6.95481 7.19578 7.01484C6.52306 7.65874 5.98829 8.4327 5.62398 9.28969C5.25967 10.1467 5.07342 11.0688 5.07656 12C5.07656 15.8175 8.1825 18.9234 12 18.9234C15.8175 18.9234 18.9234 15.8175 18.9234 12C18.9234 8.40609 16.1709 5.44313 12.6633 5.10938V7.6875C12.6633 7.86341 12.5934 8.03212 12.469 8.15651C12.3446 8.2809 12.1759 8.35078 12 8.35078C11.8241 8.35078 11.6554 8.2809 11.531 8.15651C11.4066 8.03212 11.3367 7.86341 11.3367 7.6875V4.41328C11.3367 4.23737 11.4066 4.06866 11.531 3.94427C11.6554 3.81988 11.8241 3.75 12 3.75C16.5492 3.75 20.25 7.45312 20.25 12C20.25 16.5469 16.5492 20.25 12 20.25Z" />
    </svg>
  )
})

export const Clock = styled(Svg, { fill: '#C0C0C0' })
