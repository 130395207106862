import { SVGProps } from 'react'

export function RightBackground(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect width="26" height="26" rx="4" fill="black" fillOpacity="0.1" />
      <path
        d="M13.0536 8L18.2424 13.1888L13.0536 18.3776M17.5218 13.1888H7"
        stroke="#5C5C5C"
        strokeWidth="1.7296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
