export function ChevronRight(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      {...props}>
      <path
        d="M5.79561 5.52949L1.84571 9.76268C1.5727 10.0553 1.13124 10.0553 0.861136 9.76268L0.204755 9.05923C-0.0682524 8.76664 -0.0682524 8.29352 0.204755 8.00404L3.00454 5.00345L0.204756 2.00286C-0.068252 1.71028 -0.068252 1.23715 0.204756 0.947679L0.858232 0.237996C1.13124 -0.0545924 1.5727 -0.0545923 1.8428 0.237996L5.7927 4.47119C6.06861 4.76378 6.06861 5.2369 5.79561 5.52949Z"
        fill={props.color || 'black'}
      />
    </svg>
  )
}
